<template>
<header>
	<h1 class="title mr-3">
		{{licenses.title}}
	</h1>
	<div>
		<input type="checkbox" id="outdated" name="outdated" @change="toggleOutdated">
		<label for="outdated">Показывать просроченные</label>
	</div>
</header>
<ul class="list">
	<li class="list__element pointer" v-for="item in list" :key="item.id">
		<router-link :to="`/licenses/${item.id}`">
			<p>
				<span><strong>Номер лицензии:</strong> {{item.number}}</span>
				<span><b>Участок:</b> {{item.fisherRegion.name}}</span><br>
				<span><strong>Действует:</strong> с {{formatDate(item.dateStart)}} до {{formatDate(item.dateEnd)}}</span>
			</p>
		</router-link>
	</li>
</ul>
<BasePagination
	v-model:value="pagination.page"
	:max="pagination.totalPages"
	@update:value="getData"
/>
<div class="add">
	<IconPlus @click="$router.push(`/licenses/new`)" color="white" :diameter="32" circle />
</div>

</template>

<script>
// import TheBook from '@/components/TheBook'
// - TheBook(
// - 	:title="licenses.title"
// - 	:endpoint="licenses.endpoint"
// - 	:path="licenses.path"
// - 	:left="licenses.left"
// - 	:leftSub="licenses.leftSub"
// - )
import BOOKS from '@/modules/constants/books'

import { onMounted, reactive, ref } from 'vue'
import { useApi } from '@/modules/api'

import BasePagination from '@/components/ui/BasePagination'
import IconPlus from '@/components/ui/icons/IconPlus'

// &showOutdated=true

export default {
	name: 'Licenses',
	components: { BasePagination, IconPlus },
	setup () {
		const { licenses } = BOOKS
		const list = ref([])
		const showOutdated = ref(false)

		const pagination = reactive({
			show: true,
			page: 0,
			size: 20,
			totalPages: 0
		})

		const getData = async () => {
			const { get, data } = useApi(licenses.endpoint)

			const urlParametrs = `?offset=0&page=${pagination.page}&size=${pagination.size}&sort=date_of_issue,desc&showOutdated=${showOutdated.value}`

			await get(urlParametrs)

			const { content, totalPages } = data.value
			pagination.totalPages = totalPages

			list.value = content
		}

		const formatDate = (unixTimeStamp) => {
			const options = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			}
			const date = new Date(unixTimeStamp * 1000)
			return date.toLocaleString('ru', options)
		}

		const toggleOutdated = async (e) => {
			showOutdated.value = e.target.checked
			await getData()
		}

		onMounted(async () => {
			await getData()
		})

		return {
			licenses,
			list,
			pagination,
			getData,
			formatDate,
			toggleOutdated
		}
	}
}
</script>

<style lang="scss" scoped>
header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	label {
		margin-left: 4px;
	}
}
.list {
	margin-top: 20px;
	&__element {
		transition: background-color 0.3s ease;
		padding: 4px;
		display: grid;

		p {
			b {
				display: block;
				margin-bottom: 2px;
			}
		}

		@include until-mobile {
			p + p {
				margin-top: 4px;
			}
		}

		@include for-tablet {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				&:last-child {
					// text-align: right;
				}
			}

			&.hover {
				&:hover {
					background-color: rgba($bgMain, 0.1);
				}
			}
		}

		& + & {
			border-top: 1px solid $borderPrimary;
		}
	}
}
.add {
	position: fixed;
	top: 60px;
	right: 20px;
}
</style>
